import React, { useState, useEffect } from 'react'
import { useTranslation } from "react-i18next"
import { Typography, AppBar, Toolbar, IconButton, Button, TextField, Select, MenuItem, InputLabel, FormControl } from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu'
import FilterListIcon from '@mui/icons-material/FilterList'
import clsx from 'clsx'

import UserMenu from './UserMenu'

import { getHomePage, findPage } from './pages'
import { PageNumbers } from '../../modules/pageNumbers'

import SideBar from '../../components/SideBar/SideBar'
import MenuBar from '../../components/MenuBar/MenuBar'

import { DashBoard } from './DashBoard'
import { Impressum } from '../../components/Footer/Impressum'
import { AGB } from '../../components/Footer/AGB'
import { Contact } from '../../components/Footer/Contact'

import { SynopsisModule } from '../../components/SynopsisModule/SynopsisModule'
import { SpotWeatherModuleMeteo } from '../../components/SpotWeatherModule/SpotWeatherModuleMeteo'
import { SpotWeatherTableOnly } from '../../components/SpotWeatherModule/SpotWeatherTableOnly'
import { SpotWeatherGraphicOnly } from '../../components/SpotWeatherModule/SpotWeatherGraphicOnly'

import { SingleSpotweather } from '../../components/SingleSpotweather/SingleSpotweather.js'

import { Weathermap } from '../../components/Weathermap/Weathermap.js'

 import AgrarTextTable from '../../agrarbio/AgrarTextTable'
 import BioTextTable from '../../agrarbio/BioTextTable'

 import NdrCustomerDtnTable from '../../ndr/NdrCustomerDtnTable'

import useStyles from './styles'
import { useAccessContext } from '../../modules/AccessContext'

const LandingPage = ( { logout, version } ) => {
    const { t, i18n } = useTranslation()

    const styles = useStyles()
    const accessContext = useAccessContext()
    console.log(accessContext)
    // SideBar öffnen und schließen
    const [drawerOpen, setDrawerOpen] = useState(false)
    const handleDrawerOpen = () => {
        setDrawerOpen(true)
    }
    const handleDrawerClose = () => {
        setDrawerOpen(false)
    }

    /**
     * Aufruf der Pages
     * ================
     * 
     * Pages (oder Komponenten) werden 
     *      - entweder über SideBar / MenuBar 
     *      - oder andere Komponenten aufgerufen
     * Sidebar / MenuBar nutzen indirekt die Funktion 'handleSelectPage' (übergeben über das Property 'onSelect')
     *      'handleSelectPage(page)' setzt den Status 'selectedPage' ohne weitere Aufruf Argumente (leert das Argument Objekt)
     * Andere Komponenten können andere Pages über die Funktion 'handleGoToPage' (übergeben über das Property 'goTo') aufrufen
     *      'handleGoToPage(pageNumber, args)'
     *          - prüft, ob die zur pageNumber gehörige Page in den freigegebenen Pages vorkommt
     *          - setzt ggf. 
     *              - den Status 'selectedPage' mit der entsprechenden Page
     *              - und die Argumente 'args' für den Page Aufruf
     * Komponenten (Pages), die Aufruf Argumente empfangen möchten, müssen das Property 'gotoArgs' unterstützen
     *      Über 'gotoArgs' werden die Aufruf Argumente weiter gereicht.
     * 
     * Es gibt noch eine weitere Funktion 'isGoToAllowed' (isGoToAllowed(pageNumber)), die überprüft, 
     *      ob ein Aufruf der zugehörigen Page möglich (=erlaubt) ist 
     * 
     * Ggf. müssen diese Properties ('onSelect' / 'goTo', 'isGoToAllowed' / 'gotoArgs') an tieferliegende Komponenten weiter gereicht werden
     * 
     */

    const appName = accessContext.payload.customerName

    const homePage = getHomePage(accessContext.pages)
    const [selectedPage, setSelectedPage] = useState({ page: homePage, gotoArgs: {} } )
    function handleSelectPage(page) {   // MenuBar und SideBar
        setSelectedPage({ page: page, gotoArgs: { loopIndex: page.loopIndex} }) // f.a. pages mit loop indices
    }
    function handleGoToPage(pageNumber, args) { // alle anderen komponenten
        let page = findPage(accessContext.pages, pageNumber, args)
        if (page) {
            setSelectedPage({ page: page, gotoArgs: args })
        }
    }
    function isGoToAllowed(pageNumber, args={} ) {
        return findPage(accessContext.pages, pageNumber, args) !== undefined
    }

    // Filter zurücksetzen, wenn page wechselt
    useEffect(() => {
        let moduleStyle = 
            (selectedPage.page.pageNumber === PageNumbers.AstroParent || selectedPage.page.pageNumber === PageNumbers.SpotWeatherMap) ? 
            styles.moduleWithoutMargin : styles.module
        setState(state => ({ ...state, filter: '', moduleStyle: moduleStyle }))
    }, [selectedPage, styles])

    // State (filter, ... ?)
    const [state, setState] = useState({ filter: '', moduleStyle: styles.module })
    function handleFilter(event) {
        setState(state => ({ ...state, filter: event.target.value }))
    }

    const [update, setUpdate] = useState(0)
    const reload = () => {
        setUpdate(update + 1)
    }
    const impressum = () => {
      handleGoToPage( PageNumbers.Impressum, { styles: styles } )
    }
    const contact = () => {
      handleGoToPage( PageNumbers.Contact, { styles: styles } )
    }
    const agb = () => {
      handleGoToPage( PageNumbers.AGB, { styles: styles } )
    }
const precipitation_test = () => {
  window.open("https://portal.wetterwelt.de/radar", '_blank').focus();
}
    // useEffect(() => {
    //   if (accessContext.payload.access.includes("sc_ndrCustomerDtns")) {
    //     handleDrawerOpen()
    //   }
    // }, [accessContext.payload.access])
    let showFooterNavBar = false

    return <>
        <AppBar position="relative" className={clsx(styles.appBar, {[styles.appBarShift]: drawerOpen})}>
            <Toolbar className={styles.toolBar}>
                <IconButton
                  className={styles.toolBarButton}
                  color="inherit"
                  onClick={handleDrawerOpen}
                  size="large">
                    <MenuIcon className={styles.toolBarIcon}/>
                </IconButton>
                <img src="images/logo.png" alt="WetterWelt" width="125" height="auto" className={styles.logo}/>

                <div>
                    <Typography variant="subtitle2" className={styles.toolbarTitle}>{appName}</Typography>
                    <Typography variant="h4">
                        {t(selectedPage.page.title ? selectedPage.page.title : selectedPage.page.text, { textArg: selectedPage.page.textArg })}
                    </Typography>
                </div>
                <Typography className={styles.spacer1} variant="h5"/>

                { selectedPage.page.filter && (
                    <>
                        <FilterListIcon className={styles.toolBarIcon} />
                        <TextField variant="standard" size="small" className={styles.filter} value={state.filter} onChange={handleFilter} placeholder={t("appbar_filter")} />
                    </>
                )}
                { accessContext.payload.access.includes('sc_all') && (
                    <>
                        <FormControl variant="standard" className={styles.languageControl}>
                            <InputLabel className={styles.languageLabel} id="demo-simple-select-label">{t("appbar_language")}</InputLabel>
                            <Select variant="standard" className={styles.language}
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={i18n.language}
                                onChange={(e) =>
                                    i18n.changeLanguage(e.target.value)
                                }
                            >
                                <MenuItem value="de">de</MenuItem>
                                <MenuItem value="en">en</MenuItem>
                            </Select>
                        </FormControl>
                    </>
                )}
                <UserMenu/>
            </Toolbar>
            <MenuBar items={accessContext.pages} currentItem={selectedPage.page} homePage={homePage} onSelect={handleSelectPage} reload={reload} logout={logout} precipitation={precipitation_test} test={accessContext.payload.access.includes('sc_precipitation_radar')}></MenuBar>
        </AppBar>
        <SideBar open={drawerOpen} onClose={handleDrawerClose} items={accessContext.pages} currentItem={selectedPage.page} onSelect={handleSelectPage}/>
        <main className={clsx(styles.content, {
                [styles.contentShift]: drawerOpen
            })}>
            <div className={state.moduleStyle}>

                { selectedPage.page.pageNumber === PageNumbers.Home && (
                    <DashBoard 
                        update={update}
                        version={version}
                        goTo={handleGoToPage} 
                        pages={accessContext.pages}
                        styles={styles}
                    />
                ) }
                { selectedPage.page.pageNumber === PageNumbers.Impressum && (
                    <Impressum 
                        styles={styles}
                    />
                ) }
                { selectedPage.page.pageNumber === PageNumbers.Contact && (
                    <Contact 
                        styles={styles}
                    />
                ) }
                { selectedPage.page.pageNumber === PageNumbers.AGB && (
                    <AGB 
                        styles={styles}
                    />
                ) }


                { selectedPage.page.pageNumber === PageNumbers.NdrCustomerDtns && (
                    <NdrCustomerDtnTable accessToken={accessContext.token} environment={accessContext.environment} 
                        filterGlobal={state.filter} 
                        condition_keyvals={{customerId: accessContext.configuration.customerIdFix}}
                        loginName={accessContext.payload.name}/>
                    )}
                { selectedPage.page.pageNumber === PageNumbers.NdrCustomerDtnsMesswerteNorddeutschland && (
                  <NdrCustomerDtnTable accessToken={accessContext.token} environment={accessContext.environment} filterGlobal={state.filter} loginName={accessContext.payload.name}
                    condition_keyvals={{customerId: accessContext.configuration.customerIdFix,infoCategory:"Messwerte/Norddeutschland"}}
                  />)}
                { selectedPage.page.pageNumber === PageNumbers.NdrCustomerDtnsMesswerteD_EU_WELT && (
                  <NdrCustomerDtnTable accessToken={accessContext.token} environment={accessContext.environment} filterGlobal={state.filter} loginName={accessContext.payload.name}
                    condition_keyvals={{customerId: accessContext.configuration.customerIdFix,infoCategory:"Messwerte/D EU Welt"}}
                  />)}
                { selectedPage.page.pageNumber === PageNumbers.NdrCustomerDtnsVorhersagen && (
                  <NdrCustomerDtnTable accessToken={accessContext.token} environment={accessContext.environment} filterGlobal={state.filter} loginName={accessContext.payload.name}
                    condition_keyvals={{customerId: accessContext.configuration.customerIdFix,infoCategory:"Vorhersagen"}}
                  />)}
                { selectedPage.page.pageNumber === PageNumbers.NdrCustomerDtnsTexteNorddeutschland && (
                  <NdrCustomerDtnTable accessToken={accessContext.token} environment={accessContext.environment} filterGlobal={state.filter} loginName={accessContext.payload.name}
                    condition_keyvals={{customerId: accessContext.configuration.customerIdFix,infoCategory:"Texte/Norddeutschland"}}
                  />)}
                { selectedPage.page.pageNumber === PageNumbers.NdrCustomerDtnsTextDeutschlandNacht && (
                  <NdrCustomerDtnTable accessToken={accessContext.token} environment={accessContext.environment} filterGlobal={state.filter} loginName={accessContext.payload.name}
                    condition_keyvals={{customerId: accessContext.configuration.customerIdFix,infoCategory:"Text/Deutschland Nacht"}}
                  />)}
                { selectedPage.page.pageNumber === PageNumbers.NdrCustomerDtnsTextWetterspezial && (
                  <NdrCustomerDtnTable accessToken={accessContext.token} environment={accessContext.environment} filterGlobal={state.filter} loginName={accessContext.payload.name}
                    condition_keyvals={{customerId: accessContext.configuration.customerIdFix,infoCategory:"Text/Wetterspezial"}}
                  />)}
                { selectedPage.page.pageNumber === PageNumbers.NdrCustomerDtnsTextNiedersachsenRegional && (
                  <NdrCustomerDtnTable accessToken={accessContext.token} environment={accessContext.environment} filterGlobal={state.filter} loginName={accessContext.payload.name}
                    condition_keyvals={{customerId: accessContext.configuration.customerIdFix,infoCategory:"Text/Niedersachsen Regional"}}
                  />)}
                { selectedPage.page.pageNumber === PageNumbers.NdrCustomerDtnsTextAgrarBioPollen && (
                  <NdrCustomerDtnTable accessToken={accessContext.token} environment={accessContext.environment} filterGlobal={state.filter} loginName={accessContext.payload.name}
                    condition_keyvals={{customerId: accessContext.configuration.customerIdFix,infoCategory:"Text/Agrar Bio Pollen"}}
                  />)}
                { selectedPage.page.pageNumber === PageNumbers.NdrCustomerDtnsTextWasser && (
                  <NdrCustomerDtnTable accessToken={accessContext.token} environment={accessContext.environment} filterGlobal={state.filter} loginName={accessContext.payload.name}
                    condition_keyvals={{customerId: accessContext.configuration.customerIdFix,infoCategory:"Text/Wasser"}}
                  />)}
                { selectedPage.page.pageNumber === PageNumbers.NdrCustomerDtnsTextWintersport && (
                  <NdrCustomerDtnTable accessToken={accessContext.token} environment={accessContext.environment} filterGlobal={state.filter} loginName={accessContext.payload.name}
                    condition_keyvals={{customerId: accessContext.configuration.customerIdFix,infoCategory:"Text/Wintersport"}}
                  />)}
                { selectedPage.page.pageNumber === PageNumbers.NdrCustomerDtnsTextNdrTeletext && (
                  <NdrCustomerDtnTable accessToken={accessContext.token} environment={accessContext.environment} filterGlobal={state.filter} loginName={accessContext.payload.name}
                    condition_keyvals={{customerId: accessContext.configuration.customerIdFix,infoCategory:"NDR/Teletext"}}
                  />)}
                { selectedPage.page.pageNumber === PageNumbers.NdrCustomerDtnsTextNdrOnline && (
                  <NdrCustomerDtnTable accessToken={accessContext.token} environment={accessContext.environment} filterGlobal={state.filter} loginName={accessContext.payload.name}
                    condition_keyvals={{customerId: accessContext.configuration.customerIdFix,infoTitle:"NDR/Online"}}
                  />)}
                { selectedPage.page.pageNumber === PageNumbers.NdrCustomerDtnsTextInfo && (
                  <NdrCustomerDtnTable accessToken={accessContext.token} environment={accessContext.environment} filterGlobal={state.filter} loginName={accessContext.payload.name}
                    condition_keyvals={{customerId: accessContext.configuration.customerIdFix,infoCategory:"Info"}}
                  />)}
                { selectedPage.page.pageNumber === PageNumbers.NdrCustomerDtnsTextUnwetterwarnung && (
                  <NdrCustomerDtnTable accessToken={accessContext.token} environment={accessContext.environment} filterGlobal={state.filter} loginName={accessContext.payload.name}
                    condition_keyvals={{customerId: accessContext.configuration.customerIdFix,infoCategory:"Text/Unwetterwarnung"}}
                  />)}
                { selectedPage.page.pageNumber === PageNumbers.NdrCustomerDtnsTexteNorddeutsche_bundeslaender && (
                  <NdrCustomerDtnTable accessToken={accessContext.token} environment={accessContext.environment} filterGlobal={state.filter} loginName={accessContext.payload.name}
                    condition_keyvals={{customerId: accessContext.configuration.customerIdFix,infoCategory:"Texte/Norddeutsche Bundeslaender"}}
                  />)} 


                 { selectedPage.page.pageNumber === PageNumbers.AgrarTexts && (
                  <AgrarTextTable accessToken={accessContext.token} environment={accessContext.environment} filterGlobal={state.filter} loginName={accessContext.payload.name}
                    condition_keyvals={{}}
                  />)}
                { selectedPage.page.pageNumber === PageNumbers.AgrarTextsFrühling && (
                  <AgrarTextTable accessToken={accessContext.token} environment={accessContext.environment} filterGlobal={state.filter} loginName={accessContext.payload.name}
                    condition_keyvals={{jahreszeit:"Frühling"}}
                  />)}
                { selectedPage.page.pageNumber === PageNumbers.AgrarTextsSommer && (
                  <AgrarTextTable accessToken={accessContext.token} environment={accessContext.environment} filterGlobal={state.filter} loginName={accessContext.payload.name}
                    condition_keyvals={{jahreszeit:"Sommer"}}
                  />)}
                { selectedPage.page.pageNumber === PageNumbers.AgrarTextsHerbst && (
                  <AgrarTextTable accessToken={accessContext.token} environment={accessContext.environment} filterGlobal={state.filter} loginName={accessContext.payload.name}
                    condition_keyvals={{jahreszeit:"Herbst"}}
                  />)}
                { selectedPage.page.pageNumber === PageNumbers.AgrarTextsWinter && (
                  <AgrarTextTable accessToken={accessContext.token} environment={accessContext.environment} filterGlobal={state.filter} loginName={accessContext.payload.name}
                    condition_keyvals={{jahreszeit:"Winter"}}
                  />)}

                { selectedPage.page.pageNumber === PageNumbers.BioTexts && (
                  <BioTextTable accessToken={accessContext.token} environment={accessContext.environment} filterGlobal={state.filter} loginName={accessContext.payload.name}
                    condition_keyvals={{}}
                  />)}
                { selectedPage.page.pageNumber === PageNumbers.BioTextsFrühling && (
                  <BioTextTable accessToken={accessContext.token} environment={accessContext.environment} filterGlobal={state.filter} loginName={accessContext.payload.name}
                    condition_keyvals={{jahreszeit:"Frühling"}}
                  />)}
                  { selectedPage.page.pageNumber === PageNumbers.BioTextsFrühlingSchoenwetter_angenehm && (
                  <BioTextTable accessToken={accessContext.token} environment={accessContext.environment} filterGlobal={state.filter} loginName={accessContext.payload.name}
                    condition_keyvals={{jahreszeit:"Frühling",zustand:"Schönwetter/angenehm"}}
                  />)}
                { selectedPage.page.pageNumber === PageNumbers.BioTextsFrühlingExtrem_mild_sehr_heiss && (
                  <BioTextTable accessToken={accessContext.token} environment={accessContext.environment} filterGlobal={state.filter} loginName={accessContext.payload.name}
                    condition_keyvals={{jahreszeit:"Frühling",zustand:"extrem mild/sehr heiß"}}
                  />)}
                { selectedPage.page.pageNumber === PageNumbers.BioTextsFrühlingNaehe_zum_Tiefauslaeufer && (
                  <BioTextTable accessToken={accessContext.token} environment={accessContext.environment} filterGlobal={state.filter} loginName={accessContext.payload.name}
                    condition_keyvals={{jahreszeit:"Frühling",zustand:"Nähe zum Tiefausläufer"}}
                  />)}
                { selectedPage.page.pageNumber === PageNumbers.BioTextsFrühlingFront_und_Tiefkernbereich && (
                  <BioTextTable accessToken={accessContext.token} environment={accessContext.environment} filterGlobal={state.filter} loginName={accessContext.payload.name}
                    condition_keyvals={{jahreszeit:"Frühling",zustand:"Front- und Tiefkernbereich"}}
                  />)}
                { selectedPage.page.pageNumber === PageNumbers.BioTextsFrühlingWarmsektor && (
                  <BioTextTable accessToken={accessContext.token} environment={accessContext.environment} filterGlobal={state.filter} loginName={accessContext.payload.name}
                    condition_keyvals={{jahreszeit:"Frühling",zustand:"Warmsektor"}}
                  />)}
                { selectedPage.page.pageNumber === PageNumbers.BioTextsFrühlingRueckseitenwetter && (
                  <BioTextTable accessToken={accessContext.token} environment={accessContext.environment} filterGlobal={state.filter} loginName={accessContext.payload.name}
                    condition_keyvals={{jahreszeit:"Frühling",zustand:"Rückseitenwetter"}}
                  />)}
                { selectedPage.page.pageNumber === PageNumbers.BioTextsFrühlingNasskalt_Nebel && (
                  <BioTextTable accessToken={accessContext.token} environment={accessContext.environment} filterGlobal={state.filter} loginName={accessContext.payload.name}
                    condition_keyvals={{jahreszeit:"Frühling",zustand:"Nasskalt/Nebel"}}
                  />)}
                { selectedPage.page.pageNumber === PageNumbers.BioTextsFrühlingStrenge_Winterlage && (
                  <BioTextTable accessToken={accessContext.token} environment={accessContext.environment} filterGlobal={state.filter} loginName={accessContext.payload.name}
                    condition_keyvals={{jahreszeit:"Frühling",zustand:"Strenge Winterlage"}}
                  />)}
                { selectedPage.page.pageNumber === PageNumbers.BioTextsFrühlingEgal && (
                  <BioTextTable accessToken={accessContext.token} environment={accessContext.environment} filterGlobal={state.filter} loginName={accessContext.payload.name}
                    condition_keyvals={{jahreszeit:"Frühling",zustand:"Egal"}}
                  />)}
                { selectedPage.page.pageNumber === PageNumbers.BioTextsSommer && (
                  <BioTextTable accessToken={accessContext.token} environment={accessContext.environment} filterGlobal={state.filter} loginName={accessContext.payload.name}
                    condition_keyvals={{jahreszeit:"Sommer"}}
                  />)}
                  { selectedPage.page.pageNumber === PageNumbers.BioTextsSommerSchoenwetter_angenehm && (
                  <BioTextTable accessToken={accessContext.token} environment={accessContext.environment} filterGlobal={state.filter} loginName={accessContext.payload.name}
                    condition_keyvals={{jahreszeit:"Sommer",zustand:"Schönwetter/angenehm"}}
                  />)}
                { selectedPage.page.pageNumber === PageNumbers.BioTextsSommerExtrem_mild_sehr_heiss && (
                  <BioTextTable accessToken={accessContext.token} environment={accessContext.environment} filterGlobal={state.filter} loginName={accessContext.payload.name}
                    condition_keyvals={{jahreszeit:"Sommer",zustand:"extrem mild/sehr heiß"}}
                  />)}
                { selectedPage.page.pageNumber === PageNumbers.BioTextsSommerNaehe_zum_Tiefauslaeufer && (
                  <BioTextTable accessToken={accessContext.token} environment={accessContext.environment} filterGlobal={state.filter} loginName={accessContext.payload.name}
                    condition_keyvals={{jahreszeit:"Sommer",zustand:"Nähe zum Tiefausläufer"}}
                  />)}
                { selectedPage.page.pageNumber === PageNumbers.BioTextsSommerFront_und_Tiefkernbereich && (
                  <BioTextTable accessToken={accessContext.token} environment={accessContext.environment} filterGlobal={state.filter} loginName={accessContext.payload.name}
                    condition_keyvals={{jahreszeit:"Sommer",zustand:"Front- und Tiefkernbereich"}}
                  />)}
                { selectedPage.page.pageNumber === PageNumbers.BioTextsSommerWarmsektor && (
                  <BioTextTable accessToken={accessContext.token} environment={accessContext.environment} filterGlobal={state.filter} loginName={accessContext.payload.name}
                    condition_keyvals={{jahreszeit:"Sommer",zustand:"Warmsektor"}}
                  />)}
                { selectedPage.page.pageNumber === PageNumbers.BioTextsSommerRueckseitenwetter && (
                  <BioTextTable accessToken={accessContext.token} environment={accessContext.environment} filterGlobal={state.filter} loginName={accessContext.payload.name}
                    condition_keyvals={{jahreszeit:"Sommer",zustand:"Rückseitenwetter"}}
                  />)}
                { selectedPage.page.pageNumber === PageNumbers.BioTextsSommerNasskalt_Nebel && (
                  <BioTextTable accessToken={accessContext.token} environment={accessContext.environment} filterGlobal={state.filter} loginName={accessContext.payload.name}
                    condition_keyvals={{jahreszeit:"Sommer",zustand:"Nasskalt/Nebel"}}
                  />)}
                { selectedPage.page.pageNumber === PageNumbers.BioTextsSommerStrenge_Winterlage && (
                  <BioTextTable accessToken={accessContext.token} environment={accessContext.environment} filterGlobal={state.filter} loginName={accessContext.payload.name}
                    condition_keyvals={{jahreszeit:"Sommer",zustand:"Strenge Winterlage"}}
                  />)}
                { selectedPage.page.pageNumber === PageNumbers.BioTextsSommerEgal && (
                  <BioTextTable accessToken={accessContext.token} environment={accessContext.environment} filterGlobal={state.filter} loginName={accessContext.payload.name}
                    condition_keyvals={{jahreszeit:"Sommer",zustand:"Egal"}}
                  />)}
                { selectedPage.page.pageNumber === PageNumbers.BioTextsHerbst && (
                  <BioTextTable accessToken={accessContext.token} environment={accessContext.environment} filterGlobal={state.filter} loginName={accessContext.payload.name}
                    condition_keyvals={{jahreszeit:"Herbst"}}
                  />)}
                  { selectedPage.page.pageNumber === PageNumbers.BioTextsHerbstSchoenwetter_angenehm && (
                  <BioTextTable accessToken={accessContext.token} environment={accessContext.environment} filterGlobal={state.filter} loginName={accessContext.payload.name}
                    condition_keyvals={{jahreszeit:"Herbst",zustand:"Schönwetter/angenehm"}}
                  />)}
                { selectedPage.page.pageNumber === PageNumbers.BioTextsHerbstExtrem_mild_sehr_heiss && (
                  <BioTextTable accessToken={accessContext.token} environment={accessContext.environment} filterGlobal={state.filter} loginName={accessContext.payload.name}
                    condition_keyvals={{jahreszeit:"Herbst",zustand:"extrem mild/sehr heiß"}}
                  />)}
                { selectedPage.page.pageNumber === PageNumbers.BioTextsHerbstNaehe_zum_Tiefauslaeufer && (
                  <BioTextTable accessToken={accessContext.token} environment={accessContext.environment} filterGlobal={state.filter} loginName={accessContext.payload.name}
                    condition_keyvals={{jahreszeit:"Herbst",zustand:"Nähe zum Tiefausläufer"}}
                  />)}
                { selectedPage.page.pageNumber === PageNumbers.BioTextsHerbstFront_und_Tiefkernbereich && (
                  <BioTextTable accessToken={accessContext.token} environment={accessContext.environment} filterGlobal={state.filter} loginName={accessContext.payload.name}
                    condition_keyvals={{jahreszeit:"Herbst",zustand:"Front- und Tiefkernbereich"}}
                  />)}
                { selectedPage.page.pageNumber === PageNumbers.BioTextsHerbstWarmsektor && (
                  <BioTextTable accessToken={accessContext.token} environment={accessContext.environment} filterGlobal={state.filter} loginName={accessContext.payload.name}
                    condition_keyvals={{jahreszeit:"Herbst",zustand:"Warmsektor"}}
                  />)}
                { selectedPage.page.pageNumber === PageNumbers.BioTextsHerbstRueckseitenwetter && (
                  <BioTextTable accessToken={accessContext.token} environment={accessContext.environment} filterGlobal={state.filter} loginName={accessContext.payload.name}
                    condition_keyvals={{jahreszeit:"Herbst",zustand:"Rückseitenwetter"}}
                  />)}
                { selectedPage.page.pageNumber === PageNumbers.BioTextsHerbstNasskalt_Nebel && (
                  <BioTextTable accessToken={accessContext.token} environment={accessContext.environment} filterGlobal={state.filter} loginName={accessContext.payload.name}
                    condition_keyvals={{jahreszeit:"Herbst",zustand:"Nasskalt/Nebel"}}
                  />)}
                { selectedPage.page.pageNumber === PageNumbers.BioTextsHerbstStrenge_Winterlage && (
                  <BioTextTable accessToken={accessContext.token} environment={accessContext.environment} filterGlobal={state.filter} loginName={accessContext.payload.name}
                    condition_keyvals={{jahreszeit:"Herbst",zustand:"Strenge Winterlage"}}
                  />)}
                { selectedPage.page.pageNumber === PageNumbers.BioTextsHerbstEgal && (
                  <BioTextTable accessToken={accessContext.token} environment={accessContext.environment} filterGlobal={state.filter} loginName={accessContext.payload.name}
                    condition_keyvals={{jahreszeit:"Herbst",zustand:"Egal"}}
                  />)}
                { selectedPage.page.pageNumber === PageNumbers.BioTextsWinter && (
                  <BioTextTable accessToken={accessContext.token} environment={accessContext.environment} filterGlobal={state.filter} loginName={accessContext.payload.name}
                    condition_keyvals={{jahreszeit:"Winter"}}
                  />)}
                  { selectedPage.page.pageNumber === PageNumbers.BioTextsWinterSchoenwetter_angenehm && (
                  <BioTextTable accessToken={accessContext.token} environment={accessContext.environment} filterGlobal={state.filter} loginName={accessContext.payload.name}
                    condition_keyvals={{jahreszeit:"Winter",zustand:"Schönwetter/angenehm"}}
                  />)}
                { selectedPage.page.pageNumber === PageNumbers.BioTextsWinterExtrem_mild_sehr_heiss && (
                  <BioTextTable accessToken={accessContext.token} environment={accessContext.environment} filterGlobal={state.filter} loginName={accessContext.payload.name}
                    condition_keyvals={{jahreszeit:"Winter",zustand:"extrem mild/sehr heiß"}}
                  />)}
                { selectedPage.page.pageNumber === PageNumbers.BioTextsWinterNaehe_zum_Tiefauslaeufer && (
                  <BioTextTable accessToken={accessContext.token} environment={accessContext.environment} filterGlobal={state.filter} loginName={accessContext.payload.name}
                    condition_keyvals={{jahreszeit:"Winter",zustand:"Nähe zum Tiefausläufer"}}
                  />)}
                { selectedPage.page.pageNumber === PageNumbers.BioTextsWinterFront_und_Tiefkernbereich && (
                  <BioTextTable accessToken={accessContext.token} environment={accessContext.environment} filterGlobal={state.filter} loginName={accessContext.payload.name}
                    condition_keyvals={{jahreszeit:"Winter",zustand:"Front- und Tiefkernbereich"}}
                  />)}
                { selectedPage.page.pageNumber === PageNumbers.BioTextsWinterWarmsektor && (
                  <BioTextTable accessToken={accessContext.token} environment={accessContext.environment} filterGlobal={state.filter} loginName={accessContext.payload.name}
                    condition_keyvals={{jahreszeit:"Winter",zustand:"Warmsektor"}}
                  />)}
                { selectedPage.page.pageNumber === PageNumbers.BioTextsWinterRueckseitenwetter && (
                  <BioTextTable accessToken={accessContext.token} environment={accessContext.environment} filterGlobal={state.filter} loginName={accessContext.payload.name}
                    condition_keyvals={{jahreszeit:"Winter",zustand:"Rückseitenwetter"}}
                  />)}
                { selectedPage.page.pageNumber === PageNumbers.BioTextsWinterNasskalt_Nebel && (
                  <BioTextTable accessToken={accessContext.token} environment={accessContext.environment} filterGlobal={state.filter} loginName={accessContext.payload.name}
                    condition_keyvals={{jahreszeit:"Winter",zustand:"Nasskalt/Nebel"}}
                  />)}
                { selectedPage.page.pageNumber === PageNumbers.BioTextsWinterStrenge_Winterlage && (
                  <BioTextTable accessToken={accessContext.token} environment={accessContext.environment} filterGlobal={state.filter} loginName={accessContext.payload.name}
                    condition_keyvals={{jahreszeit:"Winter",zustand:"Strenge Winterlage"}}
                  />)}
                { selectedPage.page.pageNumber === PageNumbers.BioTextsWinterEgal && (
                  <BioTextTable accessToken={accessContext.token} environment={accessContext.environment} filterGlobal={state.filter} loginName={accessContext.payload.name}
                    condition_keyvals={{jahreszeit:"Winter",zustand:"Egal"}}
                  />)}
                { selectedPage.page.pageNumber === PageNumbers.BioTextsAllgemein && (
                  <BioTextTable accessToken={accessContext.token} environment={accessContext.environment} filterGlobal={state.filter} loginName={accessContext.payload.name}
                    condition_keyvals={{jahreszeit:"Allgemein"}}
                  />)}
                  { selectedPage.page.pageNumber === PageNumbers.BioTextsAllgemeinSchoenwetter_angenehm && (
                  <BioTextTable accessToken={accessContext.token} environment={accessContext.environment} filterGlobal={state.filter} loginName={accessContext.payload.name}
                    condition_keyvals={{jahreszeit:"Allgemein",zustand:"Schönwetter/angenehm"}}
                  />)}
                { selectedPage.page.pageNumber === PageNumbers.BioTextsAllgemeinExtrem_mild_sehr_heiss && (
                  <BioTextTable accessToken={accessContext.token} environment={accessContext.environment} filterGlobal={state.filter} loginName={accessContext.payload.name}
                    condition_keyvals={{jahreszeit:"Allgemein",zustand:"extrem mild/sehr heiß"}}
                  />)}
                { selectedPage.page.pageNumber === PageNumbers.BioTextsAllgemeinNaehe_zum_Tiefauslaeufer && (
                  <BioTextTable accessToken={accessContext.token} environment={accessContext.environment} filterGlobal={state.filter} loginName={accessContext.payload.name}
                    condition_keyvals={{jahreszeit:"Allgemein",zustand:"Nähe zum Tiefausläufer"}}
                  />)}
                { selectedPage.page.pageNumber === PageNumbers.BioTextsAllgemeinFront_und_Tiefkernbereich && (
                  <BioTextTable accessToken={accessContext.token} environment={accessContext.environment} filterGlobal={state.filter} loginName={accessContext.payload.name}
                    condition_keyvals={{jahreszeit:"Allgemein",zustand:"Front- und Tiefkernbereich"}}
                  />)}
                { selectedPage.page.pageNumber === PageNumbers.BioTextsAllgemeinWarmsektor && (
                  <BioTextTable accessToken={accessContext.token} environment={accessContext.environment} filterGlobal={state.filter} loginName={accessContext.payload.name}
                    condition_keyvals={{jahreszeit:"Allgemein",zustand:"Warmsektor"}}
                  />)}
                { selectedPage.page.pageNumber === PageNumbers.BioTextsAllgemeinRueckseitenwetter && (
                  <BioTextTable accessToken={accessContext.token} environment={accessContext.environment} filterGlobal={state.filter} loginName={accessContext.payload.name}
                    condition_keyvals={{jahreszeit:"Allgemein",zustand:"Rückseitenwetter"}}
                  />)}
                { selectedPage.page.pageNumber === PageNumbers.BioTextsAllgemeinNasskalt_Nebel && (
                  <BioTextTable accessToken={accessContext.token} environment={accessContext.environment} filterGlobal={state.filter} loginName={accessContext.payload.name}
                    condition_keyvals={{jahreszeit:"Allgemein",zustand:"Nasskalt/Nebel"}}
                  />)}
                { selectedPage.page.pageNumber === PageNumbers.BioTextsAllgemeinStrenge_Winterlage && (
                  <BioTextTable accessToken={accessContext.token} environment={accessContext.environment} filterGlobal={state.filter} loginName={accessContext.payload.name}
                    condition_keyvals={{jahreszeit:"Allgemein",zustand:"Strenge Winterlage"}}
                  />)}
                { selectedPage.page.pageNumber === PageNumbers.BioTextsAllgemeinEgal && (
                  <BioTextTable accessToken={accessContext.token} environment={accessContext.environment} filterGlobal={state.filter} loginName={accessContext.payload.name}
                    condition_keyvals={{jahreszeit:"Allgemein",zustand:"Egal"}}
                  />)}

                { selectedPage.page.pageNumber === PageNumbers.Synopsis && (
                    <SynopsisModule 
                        goTo={handleGoToPage} 
                        isGoToAllowed={isGoToAllowed}
                        update={update}
                        styles={styles}
                    />
                ) }

                { selectedPage.page.pageNumber === PageNumbers.SingleSpotweather && (
                    <SingleSpotweather 
                        accessToken={accessContext.token}
                        goTo={handleGoToPage} 
                        isGoToAllowed={isGoToAllowed}
                        update={update}
                        styles={styles}
                    />
                ) }

                { selectedPage.page.pageNumber === PageNumbers.WeatherMap && (
                    <Weathermap 
                        goTo={handleGoToPage} 
                        isGoToAllowed={isGoToAllowed}
                        update={update}
                        styles={styles}
                    />
                ) }

                {/* { selectedPage.page.pageNumber === PageNumbers.SpotWeather && (<SpotWeatherModule styles={styles} gotoArgs={selectedPage.gotoArgs} /> ) } */}
                { selectedPage.page.pageNumber === PageNumbers.SpotWeatherMeteo && (<SpotWeatherModuleMeteo styles={styles} gotoArgs={selectedPage.gotoArgs} update={update} /> ) }
                { selectedPage.page.pageNumber === PageNumbers.SpotWeatherTableOnly && (<SpotWeatherTableOnly styles={styles} gotoArgs={selectedPage.gotoArgs} update={update} /> ) }
                { selectedPage.page.pageNumber === PageNumbers.SpotWeatherGraphicOnly && (<SpotWeatherGraphicOnly styles={styles} gotoArgs={selectedPage.gotoArgs} update={update} /> ) }
                
                

            </div>
        </main>

        <footer className={clsx(styles.footer, {
                [styles.footerShift]: drawerOpen
            })}>
                <nav className={styles.bottomNavigation} >
                { showFooterNavBar &&
                <>
                    <Button color="inherit" className={styles.bottomNavButton}>{t("footer_contact")}</Button>
                    <Button color="inherit" className={styles.bottomNavButton}>{t("footer_disclaimer")}</Button>
                    <Button color="inherit" className={styles.bottomNavButton}>{t("footer_impressum")}</Button>
                </>
                }
                {/*<Button color="inherit" className={styles.bottomNavButton} onClick={ contact }>{t("footer_contact")}</Button>*/}
                <Button color="inherit" className={styles.bottomNavButton} onClick={ impressum }>{t("footer_impressum")}</Button>
                <Button color="inherit" className={styles.bottomNavButton} onClick={ agb }>{t("footer_agb")}</Button>
                </nav>
        </footer>
    </>;
}

export default LandingPage